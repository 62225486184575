import React from 'react';

import Layout from '../../components/MenuLayout';
import { StudiesView } from '../../components/Studies/StudiesView';

export default () => (
  <Layout>
    <StudiesView type="grid" />
  </Layout>
);
